<template>
  <v-simple-table dense style="width: 250px;">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-uppercase">
            (DDD) Telefone
          </th>
          <th class="text-center text-uppercase">
            Tipo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in telefones"
          :key="item.Telefone"
        >
          <td>({{ item.DDD }}) {{ item.Telefone }}</td>
          <td class="text-center">
            {{ tipoTelefone[item.Tipo] }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    telefones: {type: Array, default: [] }
  },
  mounted: function(){
  },
  setup() {
    return {
      tipoTelefone: {
          0: 'Residencial',
          1: 'Comercial',
          2: 'Celular',
          3: 'Recado',
          4: 'Fax',
          5: 'Fax',
          6: 'Telex',
          7: 'Outros',
          7: 'Fone/Fax',
      }, 
    }
  },
}
</script>
