<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-uppercase">
            Logradouro
          </th>
          <th class="text-center text-uppercase">
            Bairro
          </th>
          <th class="text-center text-uppercase">
            Cidade
          </th>
          <th class="text-center text-uppercase">
            UF
          </th>
          <th class="text-center text-uppercase">
            CEP
          </th>
          <th class="text-center text-uppercase">
            Numero
          </th>
          <th class="text-center text-uppercase">
            Complemento
          </th>
          <th class="text-center text-uppercase">
            Tipo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in enderecos"
          :key="item.Tipo_pend"
        >
          <td>{{ item.ComplEndereco_pend }}</td>
          <td class="text-center">
            {{ item.Bairro_pend }}
          </td>
          <td class="text-center">
            {{ item.Cidade_pend }}
          </td>
          <td class="text-center">
            {{ item.UF_pend }}
          </td>
          <td class="text-center">
            {{ item.CEP_pend }}
          </td>
          <td class="text-center">
            {{ item.NumEnd_pend }}
          </td>
          <td class="text-center">
            {{ item.complemento }}
          </td>
          <td class="text-center">
            {{ tipoEndereco[parseInt(item.Tipo_pend)] }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    enderecos: {type: Array, default: []}
  },
  mounted: function(){
  },
  setup() {
    return {
      tipoEndereco: {
          0: 'Principal',
          1: 'Cobrança',
          2: 'Comercial',
      }, 
    }
  },
}
</script>
