<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>

          <v-col
            v-if="this.showDadosPessoa"
            cols="9"
            sm="6"
          >
            <h3>
              {{this.dadospessoa.pessoa1.nome_pes}}
            </h3>
            <span>CPF/CNPJ: {{ this.dadospessoa.pessoa1.cpf_pes }}</span>
            <br>
            <span label="Email">{{ this.dadospessoa.pessoa1.Email_pes  }}</span>
          </v-col>

          <v-col
            v-if="this.showDadosPessoa"
            cols="9"
            sm="6">
            <v-row justify="end" style="margin-right: 50px !important;">
              <v-col cols="auto">
                <v-dialog
                v-model="dialogpass"
                persistent
                  transition="dialog-top-transition"
                  max-width="400"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small outlined color="blue">
                      <v-icon>{{ icons.mdiFormTextboxPassword }}</v-icon>
                          &nbsp; Alterar senha
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar
                        color="primary white--text"
                        dark>
                          Forneça a senha antiga e a nova senha</v-toolbar>
                      
                        <v-card-text>
                          <v-row justify="center" class="mt-4 mb-5">
                            <v-form
                            ref="form"
                            >
                              <v-text-field
                                class="mt-4"
                                v-model="senhaAntiga"
                                label="Senha antiga"
                                placeholder="********"
                                hide-details
                                :type="isPasswordVisibleA ? 'text' : 'password'"
                                :append-icon="isPasswordVisibleA ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                                @click:append="isPasswordVisibleA = !isPasswordVisibleA"
                                required
                              ></v-text-field>

                              <v-text-field
                              class="mt-4"
                                v-model="senhaNova"
                                label="Senha nova"
                                placeholder="********"
                                hide-details
                                :type="isPasswordVisibleN ? 'text' : 'password'"
                                :append-icon="isPasswordVisibleN ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                                @click:append="isPasswordVisibleN = !isPasswordVisibleN"
                                required
                              ></v-text-field>

                              <v-text-field
                                class="mt-4"
                                v-model="reSenhaNova"
                                label="Repita a senha nova"
                                placeholder="********"
                                hide-details
                                :type="isPasswordVisibleRe ? 'text' : 'password'"
                                :append-icon="isPasswordVisibleRe ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                                @click:append="isPasswordVisibleRe = !isPasswordVisibleRe"
                                required
                              ></v-text-field>

                              <v-row justify="center" style="margin-top: 30px;">
                                <v-btn
                                small
                                color="error"
                                class="mr-4"
                                @click="dialog.value = false"
                                >
                                  Cancelar
                                </v-btn>

                                <v-btn
                                  small
                                  color="green white--text"
                                  @click="alterarSenha"
                                >
                                  Alterar
                                </v-btn>
                              </v-row>
                            </v-form>
                          </v-row>
                        </v-card-text>

                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>

          <!-- Endereços -->
          <v-col cols="12">
            <v-card>
              <v-card-title>Endereços</v-card-title>
              <!--<v-card-text>
              </v-card-text>-->
              <table-enderecos
              v-if="this.showEnderecos"
              :enderecos="this.dadospessoa.listaEnderecos"></table-enderecos>
            </v-card>
          </v-col>

          <!-- Telefones -->
          <v-col cols="12">
            <v-card>
              <v-card-title>Telefones</v-card-title>
              <!--<v-card-text>
              </v-card-text>-->
              <table-telefones
              v-if="this.showTelefones"
              :telefones="this.dadospessoa.telefonespessoa"></table-telefones>
            </v-card>
          </v-col>
          
          <v-row class="mt-3 ml-1">
              <span style="font-size: 12px; color: red;">* Por motivos de segurança, não será possível alterar os telefones nem os endereços cadastrados,
                para realizar essa alteração entre em contato por telefone ou pelo whatasapp.</span>
          </v-row>
        </v-row>
      </v-card-text>

      <!--
      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
        >
          Salvar
        </v-btn>
      </v-card-text> -->
    </v-form>

    <v-snackbar
        v-model="snackbar"
        :timeout="-1"
        >
      {{ this.toastText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import TableTelefones from './TableTelefones.vue'
import TableEnderecos from './TableEnderecos.vue'
import Usuario from '@/store/Usuario'
import { mdiFormTextboxPassword, mdiEyeOutline, mdiEyeOffOutline, mdiCheckUnderlineCircle } from '@mdi/js'

import Cookie from 'js-cookie'
import Strings from '@/common/strings'

export default {
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  mounted: function(){
    this.$loading(true)
    Usuario.dadospessoa(Strings.imobiliariaId).then(result => {
      
      this.dadospessoa = result.data
      this.showDadosPessoa = true
      this.showTelefones = true
      this.showEnderecos = true

    }).catch(error =>{
      console.log(error)
    }).finally(()=>{
      this.$loading(false)
    })

  },
  components: {
    TableTelefones,
    TableEnderecos,
  },
  methods: {
    alterarSenha(){

        if(this.senhaAntiga == '' || this.senhaNova == '' || this.reSenhaNova == ''){
          this.toastText = 'Digite todas as senhas corretamente'
          this.snackbar = true
          return
        }

        if(this.senhaNova != this.reSenhaNova){
          this.toastText = 'A senha nova não confere'
          this.snackbar = true
          return
        }

        let payload = {
          ImobiliariaId: Strings.imobiliariaId,
          Senha: this.senhaAntiga,
          SenhaNova: this.senhaNova
        }

        this.dialogpass = false

        this.$loading(true)

        Usuario.alterarSenha(payload).then(result => {
          this.toastText = result.data
          this.snackbar = true
        }).catch(error => {

          if(error.response.status == 401){
              Cookie.remove(Strings.cookie_token)
              Cookie.remove(Strings.cookie_username)
              this.$router.push('/pages/login')
          }

          this.toastText = 'Não foi possível alterar a senha, reporte o problema ná página de "Reportar Bug"'
          this.snackbar = true

        }).finally(() => {
            this.senhaAntiga = ''
            this.senhaNova = ''
            this.reSenhaNova = ''
            this.$loading(false)
        })
    }
  },
  data(){
    return{
      senhaAntiga: '',
      senhaNova: '',
      reSenhaNova: '',
      dialogpass: false
    }
  },
  setup(props) {
    const isPasswordVisibleA = ref(false)
    const isPasswordVisibleN = ref(false)
    const isPasswordVisibleRe = ref(false)

    const snackbar = ref(false)
    const toastText = ref('')

    const dadospessoa = new Object()

    const showDadosPessoa = false
    const showEnderecos = false
    const showTelefones = false

    const optionsLocal = ref(JSON.parse(JSON.stringify(props.informationData)))

    const resetForm = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(props.informationData))
    }

    return {
      optionsLocal,
      resetForm,
      dadospessoa,
      showDadosPessoa,
      showEnderecos,
      showTelefones,
      icons:{
        mdiFormTextboxPassword,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCheckUnderlineCircle
      },
      snackbar,
      toastText,
      isPasswordVisibleA,
      isPasswordVisibleN,
      isPasswordVisibleRe
    }
  },
}
</script>
